import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import {
  mapCardsData,
  servicesData,
  whatWeDoCardsData,
} from "../constants/WebsiteData";
import MapCard from "./MapCard";

const MapsModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const isMobile = window.innerWidth < 768;

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <div>
      <div onClick={toggleModal} style={{ cursor: "pointer" }}>
        {/* Your InformationCard content here */}
        <li>
          <a
            // href="https://whatsapp.com/channel/0029VaiHDOI1yT216KG9uQ1U"
            title="Maps"
            // target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/map.png"
              alt="logo"
              style={{
                width: "32px",
              }}
            />
          </a>
        </li>
      </div>

      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        {/* Modal content here */}

        <div
          style={{
            display: "flex",
          }}
        >
          <div className="info-cards-content">
            {mapCardsData.map((card) => (
              <MapCard
                key={card.id}
                id={card.id}
                title={card.title}
                description={card.description}
                icon={card.icon}
                latitude={card.latitude}
                longitude={card.longitude}
                googleMapsUrl={card.googleMapsUrl}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MapsModal;
