import React from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Info from "../Components/Info";
import About from "../Components/About";
import BookAppointment from "../Components/BookAppointment";
import Reviews from "../Components/Reviews";
import Doctors from "../Components/Doctors";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div className="home-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anuvamsika Mulika Vaidyam - Natural Healing & Wellness</title>
        <meta
          name="description"
          content="Explore traditional and effective Anuvamsika Mulika Vaidyam treatments for various health issues. Learn more about natural remedies and holistic health practices for a better life."
        />
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          property="og:title"
          content="Anuvamsika Mulika Vaidyam - Embrace Natural Healing"
        />
        <meta
          property="og:description"
          content="Discover traditional Anuvamsika Mulika Vaidyam treatments for a wide range of health conditions. Embrace the power of natural healing and wellness for a holistic approach to health."
        />
        <meta property="og:url" content="http://mysite.com/example" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="http://mysite.com/example/og-image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Anuvamsika Mulika Vaidyam - Natural Remedies & Wellness"
        />
        <meta
          name="twitter:description"
          content="Dive into the world of Anuvamsika Mulika Vaidyam for natural and effective remedies for various health concerns. Join us on a journey to better health with holistic practices."
        />
        <meta
          name="twitter:image"
          content="http://mysite.com/example/twitter-image.jpg"
        />
        <meta
          name="keywords"
          content="Anuvamsika Mulika Vaidyam, Natural Healing, Holistic Health, Traditional Remedies, Wellness, Natural Remedies, Natural Treatment, Herbal Medicine, Health Care, Alternative Medicine, Natural Therapy, Wellness Programs, Health and Wellness, Herbal Remedies, Natural, Holistic Therapy, Natural Health Solutions, Organic Health, Natural Herbs, Medicinal Plants, Natural Medicine, Health Tips, Wellness Care, Natural Therapy, Herbal Therapy, Body Healing, Mind Wellness, Spiritual Healing, Natural Lifestyle, Health Improvement, Preventive Care, Natural Health Care"
        />
      </Helmet>
      <Navbar />
      <div style={{
        height: "90px"
      
      }}> 

      </div>
      <Hero />
      <Info />
      <About />
      <BookAppointment />
      <Reviews />
      {/* <Doctors /> */}
      <Footer />
    </div>
  );
}

export default Home;
