import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import { servicesData } from "../constants/WebsiteData";

const InformationCard = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const isMobile = window.innerWidth < 768;

  const toggleModal = () => setModalOpen(!isModalOpen);
  const midpoint = Math.ceil(props.modalList.length / 2);
  const firstHalf = props.modalList.slice(0, midpoint);
  const secondHalf = props.modalList.slice(midpoint);
  // console.log("isMobile", isMobile)
  console.log("firstHalf", firstHalf);
  console.log("secondHalf", secondHalf);

  return (
    <div>
      <div onClick={toggleModal} style={{ cursor: "pointer" }}>
        {/* Your InformationCard content here */}
        <div className="info-cards">
          <span className="info-card-icon">
            <FontAwesomeIcon className="info-fa-icon" icon={props.icon} />
          </span>
          <p className="info-card-title">{props.title}</p>
          <p
            className="info-card-description"
            style={{
              textAlign: "justify",
            }}
          >
            {props.description}

            {props.id !== 3 && (
              <span style={{ color: "blue" }}>Click here...</span>
            )}
          </p>
        </div>
      </div>
      {props.id !== 3 && (
        <Modal isOpen={isModalOpen} onClose={toggleModal}>
          {/* Modal content here */}

          <div>
            <p
              className="info-card-title"
              style={{
                textDecoration: "underline",
              }}
            >
              {props.modalTitle}
            </p>
            {!isMobile && (
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  {firstHalf.map((item, index) => (
                    <p key={index} className="ba-checks">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ color: "#14705A" }}
                      />{" "}
                      {item}
                    </p>
                  ))}
                </div>

                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  {secondHalf.map((item, index) => (
                    <p key={index} className="ba-checks">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ color: "#14705A" }}
                      />{" "}
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {isMobile && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {props.modalList.map((item, index) => (
                  <p key={index} className="ba-checks">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#14705A" }}
                    />{" "}
                    {item}
                  </p>
                ))}
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default InformationCard;
