import React, { useEffect, useState } from "react";
import Doctor from "../Assets/doctor-picture.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";
import MapsModal from "./MapsModal";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container" id="hero">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">❤️ Health comes first</p>
          <h2 className="text-title">
            Welcome to Our Herbal Treatment Hospital
          </h2>
          <p
            className="text-descritpion"
            style={{
              textAlign: "justify",
            }}
          >
            Welcome to Anuvamsika Mulika Vaidyam:
            <br />
            Your Natural Oasis Experience the ancient healing wisdom of Natural
            with ease. We connect you with qualified Natural practitioners for
            convenient online consultations. Uncover your unique dosha and
            receive personalized treatment plans that prioritize your
            well-being. Embrace a holistic path to health from the comfort of
            your home. Join us on your journey to a balanced and vibrant life!
          </p>
          <ul className="hero-social-links">
            <li>
              <a
                href="https://www.youtube.com/channel/UCpZuYgq17lFbLLB-8WsarOg"
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717571083/243a122ee4eef6463237f177183a300e_hlrmjt.png"
                  alt="logo"
                  style={{
                    width: "35px",
                  }}
                />
              </a>
            </li>

            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61560369936792"
                title="FaceBook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717571267/dasdasdas2wwwwwwwwwwwwwwwww_u7fbeb.png"
                  alt="logo"
                  style={{
                    width: "35px",
                  }}
                />
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/anuvamsika_mulika_vaidhyam/"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717571384/pngegg5464544_sxshgx.png"
                  alt="logo"
                  style={{
                    width: "32px",
                  }}
                />
              </a>
            </li>
            <li>
              <a
                href="https://whatsapp.com/channel/0029VaiHDOI1yT216KG9uQ1U"
                title="Whatsapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717758900/pngimg.com_-_whatsapp_PNG1_mskn8o.png"
                  alt="logo"
                  style={{
                    width: "32px",
                  }}
                />
              </a>
            </li>
            <MapsModal />
          </ul>
          <div className="text-stats">
            <div className="text-stats-container">
              <p>145k+</p>
              <p>Receive Patients</p>
            </div>

            <div className="text-stats-container">
              <p>100%</p>
              <p>Patients Happiness</p>
            </div>

            <div className="text-stats-container">
              <p>50+</p>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>

        {/* <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div> */}
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
