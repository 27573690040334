import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div>
    <img
      src="/icons/locationmarket.png"
      alt="Marker"
      style={{ width: "30px", height: "40px" }}
    />
    {/* {text} */}
  </div>
);

export default function GoogleMap({ mapDetails }) {
  const defaultProps = {
    center: {
      lat: mapDetails.latitude,
      lng: mapDetails.longitude,
    },
    zoom: 25,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "300", width: "100%" }}>
      <iframe
        src={mapDetails.googleMapsUrl}
        width="100%"
        height="300"
        style={{ border: 0 }} // Changed from string to object
        allowFullScreen="" // React prefers camelCase for attributes
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade" // Corrected attribute name to camelCase
      ></iframe>
    </div>
  );
}
