import React, { useState } from 'react';
import Modal from './Modal'; // Adjust the import path according to your project structure

const ModalBtn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      {/* <button
        className="navbar-btn"
        type="button"
        onClick={toggleModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <img
          src="/images/donate.png"
          alt="logo"
          style={{ width: "30px" }}
        />
        Donate Now
      </button> */}

      <Modal isOpen={isModalOpen} onClose={toggleModal} height="70vh" width="50vw">
        {/* Modal content here */}
        <div style={{
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
        }}>
          <p className="info-card-title" style={{ textDecoration: "underline",
            fontSize:"1rem",
           }}>
            {/* Content was unclear, assuming it was placeholder text */}
           Account Details

          </p>
            <p className="ba-checks" style={{
                fontSize:"0.9rem",
            }}>
                {/* Content was unclear, assuming it was placeholder text */}
                Account Name: John Doe
            </p>
            <p className="ba-checks" style={{
                fontSize:"0.9rem",
            }}>
                {/* Content was unclear, assuming it was placeholder text */}
                Account Number: 1234567890
            </p>
            <p className="ba-checks" style={{
                fontSize:"0.9rem",
            }}>
                {/* Content was unclear, assuming it was placeholder text */}
                Bank Name: Bank of America
            </p>
            <p className="ba-checks" style={{
                fontSize:"0.9rem",
            }}>
                {/* Content was unclear, assuming it was placeholder text */}
                Branch: New York
            </p>
            <p className="ba-checks" style={{
                fontSize:"0.9rem",
            }}>
                {/* Content was unclear, assuming it was placeholder text */}
                IFSC Code: 123456
            </p>


        </div>
      </Modal>
    </>
  );
};

export default ModalBtn;