import React, { useState } from "react";
import Doctor from "../Assets/doctor-book-appointment.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/BookAppointment.css";
import Modal from "./Modal";
import { servicesData } from "../constants/WebsiteData";

function BookAppointment() {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };
  const midpoint = Math.ceil(servicesData.length / 2);
  const firstHalf = servicesData.slice(0, midpoint);
  const secondHalf = servicesData.slice(midpoint);

  const isMobile = window.innerWidth < 768;

  return (
    <div id="services" className="ba-section">
      <div className="ba-image-content">
        <img
          src="https://tridoshic-ai.com/wp-content/uploads/2024/01/9011.jpg"
          alt="Doctor Group"
          className="ba-image1"
        />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Services</span>
        </h3>
        <p className="ba-description">
          Discover the reasons to choose Anuvamsika Mulika Vaidyam for your
          healthcare needs. Experience expert care, convenience, and
          personalized solutions, making your well-being our top priority. Join
          us on a journey to better health and a happier life.
        </p>

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} />{" "}
          Brain Tumor
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} />
          Alzheimer's
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} />{" "}
          Autism
        </p>
        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} />
          Paralysis
        </p>
        <>
          <button
            onClick={toggleModal}
            style={{ cursor: "pointer" }}
            className="text-appointment-btn"
            type="button"
          >
            View More
          </button>
          <Modal
            isOpen={isModalOpen}
            onClose={toggleModal}
            height="80vh"
            width="70vw"
          >
            {/* Modal content here */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className="info-card-title"
                style={{
                  textDecoration: "underline",
                }}
              >
                Services
              </p>
              {!isMobile && (
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {firstHalf.map((item, index) => (
                      <p key={index} className="ba-checks">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          style={{ color: "#14705A" }}
                        />{" "}
                        {item}
                      </p>
                    ))}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {secondHalf.map((item, index) => (
                      <p key={index} className="ba-checks">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          style={{ color: "#14705A" }}
                        />{" "}
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              )}

              {isMobile && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
          
                }}>
                  {servicesData.map((item, index) => (
                    <p key={index} className="ba-checks" style={{
                      fontSize: "1.1rem",
                      textAlign: "left",
                    }}>
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ color: "#14705A" }}
                      />{" "}
                      {item}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </Modal>
        </>
      </div>
    </div>
  );
}

export default BookAppointment;
