import {
  faHeartPulse,
  faTruckMedical,
  faTooth,
  faDisease,
} from "@fortawesome/free-solid-svg-icons";

export const whatWeDoCardsData = [
  {
    id: 1,
    title: "Diseases",
    description:
      "Mulika Vaidhyam, an ancient natural medicine system, offers holistic treatment for chronic health problems using plant-based remedies with no side effects. This approach restores balance in the body by addressing root causes, not just symptoms. Utilizing the healing properties of herbs, Mulika Vaidhyam promotes overall well-being and supports the body's natural healing processes. By integrating Mulika Vaidhyam principles, we cure the following diseases. ",
    icon: faDisease,
    modalTitle: "Diseases",
    modalDescription:
      "Mulika Vaidhyam, an ancient natural medicine system, offers holistic treatment for chronic health problems using plant-based remedies with no side effects. This approach restores balance in the body by addressing root causes, not just symptoms. Utilizing the healing properties of herbs, Mulika Vaidhyam promotes overall well-being and supports the body's natural healing processes. By integrating Mulika Vaidhyam principles, we cure the following diseases. ",
    modalList: [
      "Brain Tumour",
      "Heart problems",
      "Alzheimer",
      "Lungs disease",
      "Autism",
      "Liver disease",
      "Glaucoma",
      "Pancreas issues",
      "Psoriasis",
      "Kidney problems",
      "Paralysis",
      "Blood infections",
      "Thyroid",
      " B.P",
      "Sugar",
      "Filaria",
      "Gangrene",
      "All types of cancers",
      "Sciatica and all types of Pains",
      "Genic Problems",
      "Varicose veins",
      "We treat all Chronic Health Problems",
    ],
  },

  {
    id: 2,
    title: "Health Tips",

    description:
      "Achieving optimal well-being through Natural Mulika Vaidhyam involves integrating ancient wisdom into daily life, fostering harmony between the mind, body, and environment. This holistic approach emphasizes natural balance, preventive care, and personalized practices to enhance health and longevity. By aligning daily habits with the principles of Mulika Vaidhyam, individuals can support their body's natural healing processes and prevent chronic ailments. ",
    icon: faHeartPulse,
    modalTitle: "Health Tips",
    modalDescription:
      "Achieving optimal well-being through Natural Mulika Vaidhyam involves integrating ancient wisdom into daily life, fostering harmony between the mind, body, and environment. This holistic approach emphasizes natural balance, preventive care, and personalized practices to enhance health and longevity. By aligning daily habits with the principles of Mulika Vaidhyam, individuals can support their body's natural healing processes and prevent chronic ailments.",
    modalList: [
      "Incorporate a balanced diet with six tastes (sweet, sour, salty, bitter, pungent, astringent) to improve digestion and overall health.",
      "Practice regular detoxification through Panchakarma therapies to eliminate toxins and rejuvenate the body.",
      "Engage in daily routines (Dinacharya) including waking up early, yoga, and meditation to balance the mind and body.",
      "Use herbal remedies and supplements wisely, under the guidance of an experienced Natural practitioner, to support health and treat ailments.",
      "Stay hydrated with warm water throughout the day to aid digestion and detoxification.",
      "Exercise regularly but in moderation, according to your body type (Dosha) to maintain strength and vitality.",
      "Ensure adequate sleep and rest to rejuvenate the body and mind, aligning with the natural circadian rhythm.",
      "Manage stress through meditation, yoga, and breathing exercises (Pranayama) to maintain mental and emotional balance.",
      "Adopt seasonal routines (Ritucharya) to align your diet and lifestyle with the changing seasons, supporting natural body rhythms.",
      "Listen to your body and consult with an Natural practitioner regularly to adjust your diet and lifestyle according to changes in age, environment, and health status.",
    ],
  },

  {
    id: 3,
    title: "Home Remedies",
    description:
      "Enhance your well-being with home remedies using natural ingredients found right in your surroundings. Nature offers abundant resources that can support your oral health without having to look far. From soothing sore throats to refreshing tired eyes, simple yet effective solutions can be found just outside your door. By utilizing these natural remedies, you can promote health and well-being while reducing reliance on artificial products. Incorporate these into your daily routine to naturally enhance your health.",
    icon: faTooth,
    modalTitle: "Home Remedies",
    modalDescription:
      "Enhance your well-being with home remedies using natural ingredients found right in your surroundings. Nature offers abundant resources that can support your oral health without having to look far. From soothing sore throats to refreshing tired eyes, simple yet effective solutions can be found just outside your door. By utilizing these natural remedies, you can promote health and well-being while reducing reliance on artificial products. Incorporate these into your daily routine to naturally enhance your health.",
    modalList: [
      "Oil Pulling: Swish 1 tablespoon of coconut oil in your mouth for 15-20 minutes to remove toxins and improve oral health.",
      "Tongue Scraping: Use a copper or stainless steel tongue scraper to remove bacteria and debris from the tongue, improving digestion and oral hygiene.",
      "Herbal Mouthwash: Rinse with warm water infused with herbs like neem, clove, or peppermint to freshen breath and prevent infections.",
      "Saltwater Gargle: Mix warm water with salt to soothe a sore throat, reduce inflammation, and kill bacteria in the mouth.",
      "Turmeric Paste: Apply a paste of turmeric and water to the gums to reduce inflammation, pain, and swelling.",
      "Clove Oil: Dab clove oil on a cotton ball and apply to a toothache to relieve pain and kill bacteria.",
      "Tea Tree Oil: Mix tea tree oil with water and use as a mouthwash to reduce plaque, fight bacteria, and freshen breath.",
      "Aloe Vera Gel: Apply aloe vera gel to the gums to reduce inflammation, promote healing, and soothe oral tissues.",
      "Guava Leaves: Chew guava leaves to relieve toothache, reduce inflammation, and prevent infections.",
      "Cinnamon Powder: Mix cinnamon powder with honey and apply to the gums to reduce pain, swelling, and infection.",
    ],
  },
];

export const mapCardsData = [
  {
    id: 1,
    title: "Visakhapatnam",
    description:
      "exhibition ground, besides, Krishnarayapuram, Vepagunta, Visakhapatnam, Andhra Pradesh 530047",
    icon: faDisease,
    latitude: 17.783049,
    longitude: 83.215296,
    googleMapsUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d949.7945617835405!2d83.2151697!3d17.783318!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3967866f390ca5%3A0x9d343ec1f709303f!2sAnuvamsika%20Mulika%20Vaidhyashram!5e0!3m2!1sen!2sin!4v1725520314164!5m2!1sen!2sin",
  },
  {
    id: 2,
    title: "Vijayawada ",
    description:
      "39, Rd Number 1, Veterinary Colony, Vijayawada, Andhra Pradesh 520008",
    icon: faDisease,
    latitude: 17.783049,
    longitude: 83.215296,
    googleMapsUrl: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3825.2806140521!2d80.665836!3d16.511924999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDMwJzQyLjkiTiA4MMKwMzknNTcuMCJF!5e0!3m2!1sen!2sin!4v1718871833820!5m2!1sen!2sin",
  },
  {
    id: 3,
    title: "Hyderabad",
    description:
      "Vivekananda Nagar, Kukatpally, Hyderabad, Telangana 500072",
    icon: faDisease,
    latitude: 17.783049,
    longitude: 83.215296,
    googleMapsUrl: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3805.3000824695623!2d78.40672107516738!3d17.493182983412147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDI5JzM1LjUiTiA3OMKwMjQnMzMuNSJF!5e0!3m2!1sen!2sin!4v1718871754325!5m2!1sen!2sin",
  },
];

export const servicesData = [
  "Brain Tumor",
  "Alzheimer's",
  "Autism",
  "Glaucoma",
  "Paralysis",
  "Thyroid disorders",
  "Kidney problems",
  "High Blood Pressure",
  "Diabetes",
  "Filaria",
  "Gangrene",
  "All types of cancer",
  "Sciatica",
  "Genetic problems",
  "Varicose veins",
  "Skin diseases",
  "Hair and nail disorders",
];
