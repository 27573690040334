import React from "react";
import InformationCard from "./InformationCard";
import {
  faHeartPulse,
  faTruckMedical,
  faTooth,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";
import { whatWeDoCardsData } from "../constants/WebsiteData";

function Info() {
  return (
    <div className="info-section">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
          Our medicine is completely plant-based, so there are no side effects.
          We believe in living a balanced life, which includes eating healthy,
          seasonal foods, focusing on good digestion, and getting enough sleep.
          We also practice yoga and meditation to manage stress. Nature even
          guides our daily routines to promote overall well-being.
        </p>
      </div>

      <div className="info-cards-content">
        {whatWeDoCardsData.map((card) => (
          <InformationCard
            key={card.id}
            id={card.id}
            title={card.title}
            description={card.description}
            icon={card.icon}
            modalTitle={card.modalTitle}
            modalDescription={card.modalDescription}
            modalList={card.modalList}
          />
        ))}
      </div>
    </div>
  );
}

export default Info;
