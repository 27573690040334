import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ isOpen, onClose, children,height, width }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000, // Increase z-index to ensure modal is on top
      }}
      onClick={onClose}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
          display: "inline-block",
          minHeight: "100px",
          margin: "auto",
          position: "relative",
          border: "5px solid #14705A",
          height: height || "90vh",
          width: width || "90vw",
          overflow: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            height: "100vh",
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            onClick={onClose}
            style={{
              position: "fixed",
              left: "90%",
              padding: "10px",
              borderRadius: "100%",
              backgroundColor: "#14705A",
              color: "white",
              height: "15px",
              width: "15px",
            }}
          />
        </div>
        {children}
      </div>
    </div>
  );
};


export default Modal;
