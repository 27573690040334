import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import { servicesData } from "../constants/WebsiteData";
import GoogleMap from "./GoogleMap";

const MapCard = (props) => {
  const isMobile = window.innerWidth < 768;
  const googleMapsUrl = props.googleMapsUrl;
  console.log("googleMapsUrl", props.googleMapsUrl);
  return (
    <div>
      <div>
        {/* Your InformationCard content here */}
        <div
          className="info-cards"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="info-card-icon">
            <FontAwesomeIcon className="info-fa-icon" icon={props.icon} />
          </span>
          <p className="info-card-title">{props.title}</p>

          <GoogleMap mapDetails={props} />
          <p
            className="info-card-description "
            style={{
           textAlign: "center",
           
            }}
          >
            {props.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MapCard;
