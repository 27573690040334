import React from "react";
import "../Styles/Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";
import MapsModal from "./MapsModal";  

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <Link to="/">
              <img
                src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717418441/Anuvamsika_Mulika_Vaidyam-Logo-6_final3_g1eig0.png"
                alt="logo"
                style={{
                  width: "190px",
                }}
              />
            </Link>
            <p className="ft-description">
              We organize health camps, workshops, and awareness programs to
              educate communities about herbal medicine. We partner with local
              organizations to reach remote areas and underserved populations.
            </p>
          </div>

          {/* <SubscribeNewsletter /> */}
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Brain Tumor</a>
            </li>
            <li>
              <a href="#services">Alzheimer's</a>
            </li>
            <li>
              <a href="#services">Autism</a>
            </li>
            <li>
              <a href="#services">Paralysis</a>
            </li>
            <li>
              <a href="#services">Insights for Natural medicine</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/legal"}>General Info</Link>
            </li>
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/legal"}>Terms of Services</Link>
            </li>
            <li>
              <Link to={"/legal"}>Consultations</Link>
            </li>
            <li>
              <Link to={"/legal"}>How it Works</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:support@anuvamsikamulikavaidyam.com">
                anuvamsikamulikavaidhyam@gmail.com
              </a>
            </li>
            {/* <li>
              <a href="mailto:appointment@healthplus.com">
                appointment@anuvamsikamulikavaidyam.com
              </a>
            </li> */}
            <li>
              <a href="tel:8332866385">+91 83328 66385</a>
            </li>
            {/* <li>
              <a href="tel:+022 2326 6232">+</a>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 Anuvamsika Mulika Vaidyam. All rights reserved.</p>
        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.youtube.com/channel/UCpZuYgq17lFbLLB-8WsarOg"
              title="YouTube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717571083/243a122ee4eef6463237f177183a300e_hlrmjt.png"
                alt="logo"
                style={{
                  width: "35px",
                }}
              />
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61560369936792"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717571267/dasdasdas2wwwwwwwwwwwwwwwww_u7fbeb.png"
                alt="logo"
                style={{
                  width: "35px",
                }}
              />
            </a>
          </li>

          <li>
            <a
              href="https://www.instagram.com/anuvamsika_mulika_vaidhyam/"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717571384/pngegg5464544_sxshgx.png"
                alt="logo"
                style={{
                  width: "32px",
                }}
              />
            </a>
          </li>
          <li>
            <a
              href="https://whatsapp.com/channel/0029VaiHDOI1yT216KG9uQ1U"
              title="Whatsapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dmlhm8dwi/image/upload/v1717758900/pngimg.com_-_whatsapp_PNG1_mskn8o.png"
                alt="logo"
                style={{
                  width: "32px",
                }}
              />
            </a>
          </li>
          <MapsModal />
        </ul>
      </div>
    </div>
  );
}

export default Footer;
