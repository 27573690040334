import React from "react";
import Doctor from "../Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div
      className="about-section"
      id="about"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3 className="about-title">
        <span>About Us</span>
      </h3>
      <p className="about-description">
        I am Gude Paparao, a 61-year-old herbal practitioner from Pendurthi,
        Visakhapatnam, Andhra Pradesh, dedicated to carrying forward the legacy
        of natural herbal therapy passed down through generations of my family.
        Our hospital is a beacon of hope for those suffering from various
        debilitating diseases, offering:
      </p>
      <div
       className="about-flex-container"
      >
        <div className="about-image-content">
          <img
            src="/images/about.jpg"
            alt="Doctor Group"
            className="about-image1"
            style={{
              width: "95%",
              height: "95%",
              objectFit: "cover",
              
            }}
          />
        </div>

        <div className="about-text-content">
          <h4 className="about-text-title">Our Solutions</h4>

          <SolutionStep
            title="FREE TREATMENT "
            line1="Orphaned children: We believe every child deserves access to quality healthcare, regardless of circumstances."
            line2="Underserved communities: We strive to bridge the healthcare gap for marginalized communities."
            line3="Financially struggling patients: We offer hope and healing to those who cannot afford treatment."
          />

          <SolutionStep
            title="NOMINAL FEES for other patients"
            line1="We aim to make our treatment accessible to all, with fees that are significantly lower than conventional medicine."
          />

          <SolutionStep
            title="Team of dedicated professionals"
            line1="Our team includes experienced herbalists, doctors, and support staff united by a passion for holistic healing."
            line2="We continuously update our knowledge and skills to ensure the best possible care."
          />
          <SolutionStep
            title="Research and development"
            line1="We invest in research to advance our understanding of herbal medicine and its applications."
            line2="We collaborate with farmers to do organic farming, and planting of rare herbs.  herbal medicine innovation."
          />
        </div>
      </div>
    </div>
  );
}

export default About;
