export const customerReviews = [
  {
    name: "Venkata Surya Narayana",
    location: "Visakhapatnam, India",
    message:
      "Anuvamsika Mulika Vaidyam has been a beacon of hope for my family's health. Their holistic approach towards treatment and the warmth of their staff made us feel right at home. Highly recommended for anyone seeking quality healthcare.",
  },
  {
    name: "Lakshmi Prasanna",
    location: "Vijayawada, India",
    message:
      "Anuvamsika Mulika Vaidyam has been a beacon of hope for my family's health. Their holistic approach towards treatment and the warmth of their staff made us feel right at home. Highly recommended for anyone seeking quality healthcare.",
  },
  {
    name: "Srinivasa Rao",
    location: "Hyderabad, India",
    message:
      "The Natural treatments at Anuvamsika Mulika Vaidyam have been transformative for my overall well-being. Their personalized approach to each patient is commendable.",
  },
  {
    name: "Padmavathi",
    location: "Vijayawada, India",
    message:
      "Anuvamsika Mulika Vaidyam is a game-changer! The emergency care saved me during a critical situation. Grateful for their quick and efficient support.",
  },
  {
    name: "Rajasekhar",
    location: "Guntur, India",
    message:
      "I was skeptical about Nature, but the results speak for themselves. The team at Anuvamsika Mulika Vaidyam is knowledgeable and passionate about their craft.",
  },
  {
    name: "Anuradha",
    location: "Visakhapatnam, India",
    message:
      "Anuvamsika Mulika Vaidyam's Ayurvedic treatments have helped me manage my stress levels like nothing else. Their holistic approach to health is truly refreshing.",
  },
  {
    name: "Bhaskar Rao",
    location: "Vijayawada, India",
    message:
      "natural medicine has been a revelation for me. Anuvamsika Mulika Vaidyam's  treatments have helped me manage my chronic health issues effectively.",
  },
  {
    name: "Savitri",
    location: "Hyderabad, India",
    message:
      "I was amazed by the comprehensive approach to women's health at Anuvamsika Mulika Vaidyam. They truly understand the intricacies of female wellness.",
  },
  {
    name: "Chandra Sekhar",
    location: "Vijayawada, India",
    message:
      " Anuvamsika Mulika Vaidyam's treatments have been a game-changer for my health. Their personalized approach to each patient is commendable.",
  },
  {
    name: "Geetha",
    location: "Annavaram, India",
    message:
      "Anuvamsika Mulika Vaidyam has been a beacon of hope for my family's health. Their holistic approach towards treatment and the warmth of their staff made us feel right at home. Highly recommended for anyone seeking quality healthcare.",
  },
];
